import UserCreateEdit from '../../components/users/UserCreateEdit';
import React, {useEffect} from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../../components/Layout';
import { useUserContext } from '../../context/UserContext';
import { Authority } from '../../api/RoleManagementApi';

export default function createUserPage() {
    const { authorities } = useUserContext();
    useEffect(() => {
        authorities.includes(Authority.USER_MANAGEMENT)==false && navigate("/")
    },[]);
    return (
        <Layout allowAnonymous={false}>
            <UserCreateEdit type="create" style={{minWidth:700, width:"60%", marginLeft: "auto", marginRight: "auto"}}/>
        </Layout>
    )
}