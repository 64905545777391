import APIKit from './Base'
import { logBackend } from './LoggingApi'

export interface CompanyDto{
    id?:number,
    name?:string
}

export const countCompanies = (name) =>{
    return APIKit.get("/company/count", { params: { name: name } })
        .then<number>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const findCompanies = (limit,offset,name) =>{
    return APIKit.get("/company", { params: { limit: limit, offset: offset, name: name } })
        .then<CompanyDto[]>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const findCompanyById = (id) =>{
    return APIKit.get("/company/"+id)
        .then<CompanyDto>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const createCompany = (company: CompanyDto) =>{
    return APIKit.post("/company",company)
        .then<CompanyDto>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const updateCompany = (company: CompanyDto) =>{
    return APIKit.put("/company",company)
        .then<CompanyDto>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const deleteCompanyById = (id) =>{
    return APIKit.delete("/company/"+id)
        .then(response => {
            return response.status;
        })
        .catch(err => {
            throw(err)
        })
}