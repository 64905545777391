import APIKit from './Base'
import { RoleDto } from './RoleManagementApi'

export interface UserDto {
    id?:number,
    username?:string,
    firstName?:string,
    lastName?:string,
    mfaActivated?:boolean,
    roles?:RoleDto[],
    selected?:boolean
}
export interface UserDtoPage {
    content?:UserDto[],
    totalElements?:number
}
export enum RelationType{
    EMPLOYEE="EMPLOYEE", AUDITOR="AUDITOR", CREATOR="CREATOR"
}
export interface PersonCompanyCreateDto{
    idCompany?:number,
    relationType?:RelationType
}
export interface RegistrationDto{
    username?:string,
    firstName?:string,
    lastName?:string,
    password?:string,
    email?:string,
    personCompanyList?:PersonCompanyCreateDto[]
}
export interface PasswordDto{
    password?:string
}


export const getUsers = (page:number, size:number, filter:string) =>{
    return APIKit.get("/users", { params: { page: page, size: size, filter: filter } })
        .then<UserDtoPage>(response => {
            return response.data;
        })
        .catch(err => {
            throw(err)
        })
}

export const getUserById = (id:number) => {
    return APIKit.get("/users/"+id)
        .then<UserDto>(response => {
            return response.data})
        .catch(err => {
            throw(err)
        })
}

export const updateUser = (id:number, data: UserDto) => {
    return APIKit.put("/users/"+id,data)
        .then<UserDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}

export const deleteUser = (id:number) => {
    return APIKit.delete("/users/"+id)
        .then((response) => {
            return response.status==204
        })
        .catch((err) => {
            throw(err)
        })
}

export const registerUser = (data: RegistrationDto) => {
    return APIKit.post("/users",data)
        .then<UserDto>((response) => {
            return response.data
        })
        .catch((err) => {
            throw(err)
        })
}

export const changePassword = (id:number, data: PasswordDto) => {
    return APIKit.patch("/users/password/"+id,data)
        .then((response) => {
            return response.status==204
        })
        .catch((err) => {
            throw(err)
        })
}

export const assignRoles = (personId:number, rolesIds:string) =>{
    return APIKit.patch("/users/role/"+personId,null,{params: {roleId:rolesIds}})
        .then((response) => {
            return response.status==204
        })
        .catch((err) => {
            throw(err)
        }) 
}

export const deleteRoles = (personId:number, rolesIds:string) =>{
    return APIKit.delete("/users/role/"+personId,{params: {roleId:rolesIds}})
        .then((response) => {
            return response.status==204
        })
        .catch((err) => {
            throw(err)
        }) 
}

