import React, {useEffect, useState} from 'react'

import AutocompleteStyled from "../common/AutocompleteStyled";
import {navigate} from "gatsby";

import {useForm} from "react-hook-form";

import SearchStyled from "../common/SearchStyled";
import ButtonStyled from "../common/ButtonStyled";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import { assignRoles, changePassword, deleteRoles, getUserById, PersonCompanyCreateDto, registerUser, RegistrationDto, RelationType, updateUser, UserDto } from '../../api/UserManagementApi';
import { getRoles, RoleDto } from '../../api/RoleManagementApi';
import { CompanyDto, findCompanies } from '../../api/CompanyApi';
import { Card, CardActions, CardContent, Paper, TextField, Typography } from '@material-ui/core';
import Header from '../common/Header';
import BackendError from '../common/BackendError';
import ConfirmDialog from '../common/ConfirmDialog';
import { Autocomplete } from '@material-ui/lab';

interface UserCreateEditProps{
    id?: number,
    type: "edit" | "create",
    style?:any
}

export default function UserCreateEdit(props: UserCreateEditProps ){
    const classes = useStyles();
    const { t,i18n } = useTranslation();

    const [user, setUser] = useState<UserDto&RegistrationDto>(null)
    const [rolesFull, setRolesFull] = useState<RoleDto[]>([])
    const [personCompanyFull, setPersonCompanyFull] = useState<PersonCompanyCreateDto[]>([])
    const [companiesFull, setCompaniesFull] = useState<CompanyDto[]>([])

    const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
    const [backendError, setBackendError] = useState(null)

    const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<UserDto&RegistrationDto>({
        defaultValues: {
            email:null
        }
    });
    const usernameRegister = register("username",{required: true})
    const firstnameRegister = register("firstName")
    const lastnameRegister = register("lastName")
    const rolesRegister = register("roles")
    const emailRegister = register("email")
    const passwordRegister = register("password",props.type!="edit"&&{required:true})
    const companyRoleRegister = register("personCompanyList")

    useEffect(() => {
        if(props.type=="edit"){
            getUserById(props.id).then(res => {
                setValue("roles",res.roles)
                getRoles(null,null,null).then(rolesData =>{
                    setRolesFull(rolesData.content)
                })
                setUser(res)
            }).catch((err)=>{navigate("/404")})
        }else{
            setUser({username: "",firstName: "", lastName: "", roles: [], email:null})
            setValue("roles",[])
            getRoles(null,null,null).then(rolesData => {
                setRolesFull(rolesData.content)
            })
            findCompanies(null,null,null).then(companyData => {
                setCompaniesFull(companyData)
                let tmp : PersonCompanyCreateDto[] = []
                companyData.forEach(company => {
                    Object.values(RelationType).forEach(key =>{
                        tmp.push({idCompany:company.id,relationType:key})
                    })
                })
                setPersonCompanyFull(tmp)
            })
        }
    },[]);

    const handleRolesSelect = (values) => {
        if(values!=undefined){
            setValue("roles",values )
        }else{
            setValue("roles",[] )
        }
    }

    const handlePersonCompanySelect = (values) => {
        if(values!=undefined){
            setValue("personCompanyList",values )
        }else{
            setValue("personCompanyList",[] )
        }
    }

    const onSubmit = handleSubmit(({username,firstName, lastName, roles, email, password, personCompanyList}) => {
        if(props.type=="edit"){
            updateUser(user.id,{username:username,lastName:lastName,firstName:firstName}).then(res =>{
                roles.forEach(role=>{
                    if(user.roles.find(val => val.id==role.id)==null){
                        assignRoles(user.id,String(role.id)).catch(err => console.log(err))
                    }
                })
                user.roles.forEach(role=>{
                    if(roles.find(val => val.id==role.id)==null){
                        deleteRoles(user.id,String(role.id)).catch(err => console.log(err))
                    }
                })
                if(password!=""){
                    changePassword(res.id,{password:password}).catch(err => console.log(err))
                }
                navigate("/users/"+res.id)
            }).catch(error => {
                if(error.response!=undefined) {
                    setBackendError(i18n.language=='cs' ? error.response.data.czechMessage!=null ? error.response.data.czechMessage : error.response.data.message : error.response.data.message);
                }else setBackendError(error.message);
            })
        }else{
            registerUser({username:username,password:password,lastName:lastName,firstName:firstName,email:email!=""?email:null,personCompanyList:personCompanyList}).then(res =>{
                assignRoles(res.id,roles.map(role=>role.id).join(", ")).then().catch(err=>{
                    console.log(err)
                })
                navigate("/users/"+res.id)
            }).catch(error=>{
                if(error.response!=undefined) {
                    setBackendError(i18n.language=='cs' ? error.response.data.czechMessage!=null ? error.response.data.czechMessage : error.response.data.message : error.response.data.message);
                }else setBackendError(error.message);
            })
        }
    })

    return (
        <Paper className={classes.root} style={props.style}>
        {user!=null ?
         <Card variant="outlined">
         <Header title={props.type=="edit" ? t("EDITUSER") : t("CREATEUSER")} titleTypographyProps={{variant:"h5", align:"center"}}/>
         <CardContent>
             {backendError!=null && <BackendError error={backendError}/>}
             <Typography className={classes.fieldTitle} variant="h6">
                {t("USERNAME")}
            </Typography>
             <TextField
                 fullWidth
                 className={classes.fieldContent}
                 style={errors.username && {marginBottom: 0}}
                 id="name"
                 type="text"
                 size="small"
                 placeholder={t("USERNAME")}
                 inputRef={usernameRegister.ref}
                 onChange={usernameRegister.onChange}
                 onBlur={usernameRegister.onBlur}
                 name={usernameRegister.name}
                 inputProps={{
                     defaultValue: user.username
                 }}
             />
             {errors.username && (<div className={`${classes.formErrors} ${classes.fieldContent}`}>{t("FILLUSERNAME")}</div>)}
             <Typography className={classes.fieldTitle} variant="h6">
                {t("PASSWORD")}
            </Typography>
             <TextField
                 fullWidth
                 className={classes.fieldContent}
                 style={errors.password && {marginBottom: 0}}
                 id="name"
                 type="password"
                 size="small"
                 placeholder={t("WRITENEWPASSWORD")}
                 inputRef={passwordRegister.ref}
                 onChange={passwordRegister.onChange}
                 onBlur={passwordRegister.onBlur}
                 name={passwordRegister.name}
                 inputProps={{
                     defaultValue: user.password
                 }}
             />
             {errors.password && (<div className={`${classes.formErrors} ${classes.fieldContent}`}>{t("FILLPASSWORD")}</div>)}
             <Typography className={classes.fieldTitle} variant="h6">
                {t("FIRSTNAME")}
            </Typography>
             <TextField
                 fullWidth
                 className={classes.fieldContent}
                 style={errors.firstName && {marginBottom: 0}}
                 id="name"
                 type="text"
                 size="small"
                 placeholder={t("FIRSTNAME")}
                 inputRef={firstnameRegister.ref}
                 onChange={firstnameRegister.onChange}
                 onBlur={firstnameRegister.onBlur}
                 name={firstnameRegister.name}
                 inputProps={{
                     defaultValue: user.firstName
                 }}
             />
            <Typography className={classes.fieldTitle} variant="h6">
                {t("SURNAME")}
            </Typography>
             <TextField
                 fullWidth
                 className={classes.fieldContent}
                 style={errors.lastName && {marginBottom: 0}}
                 id="name"
                 type="text"
                 size="small"
                 placeholder={t("SURNAME")}
                 inputRef={lastnameRegister.ref}
                 onChange={lastnameRegister.onChange}
                 onBlur={lastnameRegister.onBlur}
                 name={lastnameRegister.name}
                 inputProps={{
                     defaultValue: user.lastName
                 }}
             />
            {props.type!="edit" && (<>
            <Typography className={classes.fieldTitle} variant="h6">
                {t("EMAIL")}
            </Typography>
             <TextField
                 fullWidth
                 className={classes.fieldContent}
                 style={errors.email && {marginBottom: 0}}
                 id="name"
                 type="text"
                 size="small"
                 placeholder={t("EMAIL")}
                 inputRef={emailRegister.ref}
                 onChange={emailRegister.onChange}
                 onBlur={emailRegister.onBlur}
                 name={emailRegister.name}
                 inputProps={{
                     defaultValue: user.email
                 }}
             />
            <Typography className={classes.fieldTitle} variant="h6">
                {t("PERSONCOMPANY")}
            </Typography>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={personCompanyFull}
                disableCloseOnSelect
                filterSelectedOptions
                defaultValue={user.personCompanyList}
                getOptionLabel={(company) => `${companiesFull.find(comp => comp.id==company.idCompany).name} - ${company.relationType}`}
                style={{ width: 500 }}
                onChange={(event,values) => handlePersonCompanySelect(values)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={t("SELECTCOMPANYRELATION")} placeholder={t("PERSONCOMPANY")} />
                )}
            />
            </>)}
            <Typography className={classes.fieldTitle} variant="h6">
                {t("ROLES")}
            </Typography>
            <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={rolesFull}
                disableCloseOnSelect
                filterSelectedOptions
                defaultValue={user.roles}
                getOptionLabel={(role) => `${role.name} - ${role.description}`}
                style={{ width: 500 }}
                onChange={(event,values) => handleRolesSelect(values)}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label={t("SELECTROLES")} placeholder={t("ROLES")} />
                )}
            />
         </CardContent>
         <CardActions className={classes.cardActions}>
             <ButtonStyled
                 style={{marginRight: "auto"}}
                 text={t("BACK")}
                 onClick={() => navigate(-1)}/>
             <ButtonStyled
                 text={props.type=="edit" ? t("SAVE") : t("CREATE")}
                 type="submit"
                 onClick={() => {
                     setSubmitDialogOpen(true)
                 }}/>
             <ConfirmDialog
                 title={t("SAVECHANGES")}
                 open={submitDialogOpen}
                 onClose={()=>setSubmitDialogOpen(false)}
                 onConfirm={onSubmit}
             >
                 {props.type=="edit" ? t("SUMBITCHANGESUSER",{username:user.username})+(getValues("password")!=""?"\n"+t("NEWPASSWORD",{newPassword:getValues("password")}):"") : t("REGISTERUSER")}
             </ConfirmDialog>
            </CardActions>
        </Card>
        :
        <div/>
        }
        </Paper>
    )
}